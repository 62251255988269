@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
@import "~styles/browser/_breakpoint";

.header {
  position: fixed;
  top: -72px;
  left: 0;
  width: 100%;
  height: 68px;
  border-bottom: solid 4px $gray500;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  z-index: 1450;
  &.autoHide {
    @include breakpoint(lg) {
      display: none;
    }
  }
  .headerInner {
    height: 100%;
    display: grid;
    grid-template-columns: fit-content(100%) auto fit-content(100%);
    column-gap: 40px;
    padding: 0 24px;
    a {
      .logo {
        display: flex;
        align-items: center;
        height: 100%;
        img {
          width: 48px;
          height: 48px;
        }
        span {
          font-size: $f24;
          font-weight: 700;
          color: $gray900;
          margin-left: 12px;
        }
      }
    }
    .menu {
      display: flex;
      height: 68px;
      font-size: $f20;
      font-weight: $fw500;
      .menuList {
        display: flex;
        width: 100%;
        height: 100%;
        text-align: center;
        > a {
          width: 140px;
          .menuListInner {
            display: flex;
            align-items: center;
            height: 100%;
            color: $gray500;
            > div {
              width: 100%;
            }
            &.active {
              position: relative;
              &:after {
                content: "";
                position: absolute;
                bottom: 0;
                right: 0;
                display: block;
                width: 100%;
                border: 2px solid $blue500;
              }
              > div {
                color: $blue500;
              }
            }
            &:hover {
              color: $blue500;
            }
          }
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      height: 100%;
      > div {
        &:first-child {
          margin-right: 6px;
        }
      }
      .notificationWrapper {
        &.active {
          background-color: $blue50;
        }
        margin-right: 6px;
        .iconBox {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .quantity {
            position: absolute;
            right: -7px;
            top: -7px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            font-size: $f12;
            background-color: $red500;
            color: #fff;
            padding: 3px;
            border-radius: 100%;
            &.overDigits {
              right: -13px;
              top: -8px;
              width: auto;
              height: auto;
              padding: 1px 5px;
              border-radius: 16px;
            }
          }
        }
      }
    }
  }
  &.show {
    top: 0;
  }
}
.backdrop {
  z-index: 1449 !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
}

