@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
// string:Object name size:width&height  fontSize:font-size
$avatars: "24" 24px 10px, "30" 30px $f12, "40" 40px $f16, "50" 50px 22px,
  "64" 64px $h3, "104" 104px 48px;
@each $name, $size, $fontSize in $avatars {
  .avatar#{$name} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: $size;
    height: $size;
    font-size: $fontSize;
    border-radius: 50%;
    background-color: $gray100;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
    z-index: 1;
    &.clickActive {
      cursor: pointer;
    }
    .avatarText {
      font-size: inherit;
    }
    .cameraIconBox {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      bottom: 2px;
      width: 30px;
      height: 30px;
      font-size: $f14;
      background-color: #fff;
      border-radius: 50%;
      span {
        color: $gray300;
      }
      &.active span {
        color: $blue500;
      }
    }
  }
}
