// -------- font-size* --------
$h1: 36px;
$h3: 28px;
$h9: 13px;
$f32: 32px;
$f24: 24px;
$f20: 20px;
$f18: 18px;
$f16: 16px;
$f14: 14px;
$f12: 12px;

// -------- line-height* --------
$lh64: 64px;
$lh40: 40px;
$lh36: 36px;
$lh32: 32px;
$lh30: 30px;
$lh28: 28px;
$lh26: 26px;
$lh24: 24px;
$lh22: 22px;
$lh20: 20px;
$lh18: 18px;
$lh16: 16px;
$lh14: 14px;

// -------- font-weight* --------
$fw300: 300;
$fw400: 400; // normal (regular)
$fw500: 500; // medium
$fw600: 600;
$fw700: 700;
