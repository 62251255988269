@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
@mixin fontStyle($fontSize, $fontWeight, $color) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
}

.default_display {
  @include fontStyle($f12, $fw400, $gray300);
  display: flex;
  align-items: center;
  > i {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
  }
}

.timeList {
  > li {
    @include fontStyle($f12, $fw400, $gray300);
    display: flex;
    align-items: center;
    line-height: $lh14;
    .title {
      margin-right: 4px;
    }
    &.updatedTime {
      .title {
        display: flex;
        align-items: center;
      }
    }
  }
  &.horizontal {
    display: flex;
    align-items: center;
    li {
      justify-content: center;
      @include fontStyle($f12, $fw400, $gray300);
      &.updatedTime {
        .title {
          display: flex;
          align-items: center;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
          }
        }
        &:after {
          content: "";
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: $gray300;
          border-radius: 100px;
          margin: auto 8px;
        }
      }
    }
    &.customized {
      > li {
        align-items: baseline;
        > div,
        span {
          @include fontStyle($f16, $fw400, #fff);
        }
        &:after {
          content: "";
          background-color: #fff;
        }
      }
    }
  }
}
