@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
.footer {
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  min-height: 72px;
  background-color: #e8e8e8;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    max-width: 1350px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 108px;
    padding: 0 88px;
    .copy_right {
      font-size: 13px;
      color: #727171;
      justify-self: flex-start;
      min-width: 318px;
      .security_wrapper {
        padding: 6px 0 0 0;
        .security_content_wrapper {
          float: left;
          height: 20px;
          line-height: 20px;
          margin: 0px 0px 0px 5px;
          color: #939393;
        }
        .security_content_icon {
          float: left;
          margin-left: 10px;
        }
      }
    }
    .links {
      font-size: 13px;
      justify-self: flex-end;
      justify-content: flex-end;
      display: flex;
      flex-wrap: wrap;
      max-height: 56px;
      row-gap: 8px;
      .nav_link {
        grid-row: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        a {
          grid-row: 1;
          font-size: 13px;
          color: #727171;
          text-decoration: none;
        }
        span {
          grid-row: 1;
          font-size: 13;
          color: #727171;
          text-decoration: none;
        }
        .divider {
          grid-row: 1;
          height: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: transparent;
          border-left: 1px solid $gray500;
          margin-left: 12px;
        }
      }
    }
    .qrcode {
      height: 230px;
      display: block;
      .code {
        text-align: center;
        top: 11px;
        right: 310px;
        color: #727171;
        display: flex;
        padding-top: 34px;
        flex-direction: column;
        .suggestion {
          color: #727171;
          font-size: 12px;
          padding-top: 12px;
        }
        .code_img {
          margin: 13px 0;
          text-align: center;
          display: flex;
          div {
            width: 50%;
          }
          span {
            display: block;
            font-size: 13px;
            color: #00adef;
          }
        }
      }
    }
  }
}
