@use "~styles/global/_color.scss" as *;
                  @use "~styles/global/_variable.scss" as *;
                  @use "~styles/global/_mixin.scss" as *;
.cardItem {
  display: block;
  width: 280px;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 2px 4px #00000033;
  .imgBox {
    position: relative;
    width: 100%;
    height: 158px;
    .videoTime {
      position: absolute;
      top: 8px;
      right: 8px;
      font-size: $f12;
      color: #fff;
      padding: 2px 4px;
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .cardTypeBox {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      > div {
        color: #fff;
        &.cardTypeBoxInner {
          position: relative;
          padding: 10px 14px;
          background-color: rgba(0, 0, 0, 0.5);
          .TypeTitle {
            font-size: $f16;
            color: #fff;
            font-weight: $fw500;
            margin-bottom: 5px;
          }
          .iconWithText {
            display: flex;
            align-items: center;
            > div {
              color: #fff;
              font-size: $h9;
              font-weight: normal;
              line-height: normal;
              &.iconBox {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 8px;
              }
            }
          }
        }
        &.iconType {
          position: absolute;
          right: 8px;
          bottom: 8px;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
  .cardContent {
    .cardContentInner {
      height: 100%;
      padding: 14px 14px 12px 14px;
      > div {
        color: $gray800;
        &.cardTitle {
          width: 100%;
          height: 38px;
          font-size: $f16;
          line-height: 19px;
          font-weight: $fw500;
          margin-bottom: 14px;
          @include text-overflow(2);
        }
        &.priceWrapper {
          .avatarBox {
            display: flex;
            align-items: center;
            .nameBox {
              @include text-overflow(1);
              width: calc(100% - 24px);
              font-size: $f12;
              color: $gray500;
              margin-left: 8px;
            }
          }
        }
      }
      .priceText {
        font-size: $f16;
        color: $gray900;
        font-weight: $fw500;
        padding-top: 24px;
        line-height: 20px;
        &.currency {
          margin-right: 4px;
        }
      }
    }
    .subInfo {
      width: 100%;
      padding: 0px 14px 11px;
      .subInfoInner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid $gray02;
        padding-top: 8px;
        .timeZone {
          font-size: $f12;
          color: $gray300;
        }
        .icon_wrapper {
          display: flex;
          align-items: center;
          color: $gray100;
          font-size: $f12;
          > div {
            display: flex;
            align-items: center;
            margin-right: 16px;
            &:last-child {
              margin-right: 0;
            }
          }
          .iconBox {
            display: flex;
            align-items: center;
            margin-right: 8px;
            svg {
              font-size: 20px;
            }
          }
          .enrollmentsBox {
            .iconBox {
              width: 20px;
              height: 20px;
              position: relative;
              img {
                filter: invert(89%) sepia(0%) saturate(133%) hue-rotate(18deg)
                  brightness(200%) contrast(60%);
              }
            }
          }
        }
      }
    }
  }
  &.horizontal {
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 1176px;
    padding: 16px 20px;
    .imgBox {
      max-width: 280px;
      height: 158px;
      img {
        position: relative !important;
        border-radius: 6px;
      }
      .videoTime {
        top: 8px;
        right: 8px;
      }
    }
    .cardContent {
      width: 100%;
      height: calc(158px - 32px);
      .cardContentInner {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        padding: 0 0 16px 24px;
        .cardTitle {
          margin-bottom: 12px;
        }
        .priceWrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .avatarBox {
            display: flex;
            align-items: center;
            .nameBox {
              margin-left: 8px;
            }
          }
        }
      }
      .subInfo {
        padding: 0 20px 0 24px;
      }
    }
  }
}
