$width-xl: 1350px;
$width-lg: 960px;
$width-md: 768px;
$width-sm: 428px; /*(Iphone 12 pro max viewport size)*/

// RWD BreakPoint:  x-large:1350px \ large:960px  \ middle:768px \ mobile:428px
@mixin breakpoint($point, $condition: "") {
  @if ($point == xl) {
    @media screen and (max-width: $width-xl) {
      @content;
    }
  } @else if($point == lg) {
    @media screen and (max-width: $width-lg) {
      @content;
    }
  } @else if ($point == md) {
    @media screen and (max-width: $width-md) {
      @content;
    }
  } @else if ($point == sm) {
    @media screen and (max-width: $width-sm) {
      @content;
    }
  } @else {
    @media screen and (max-width: $point) {
      @content;
    }
  }
}
