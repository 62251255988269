// -------- Blue --------
$blue11: #00aced;

$blue900: #395798;
$blue800: #126bc4;
$blue700: #0083e5;
$blue600: #0099ef;
$blue500: #00adef;
$blue400: #23c2ff;
$blue300: #56d0ff;
$blue200: #89deff;
$blue100: #abe8ff;
$blue50: #caeafe;

// -------- Green --------
$green900: #60a127;
$green800: #72ae37;
$green700: #7cb540;
$green600: #87bd4a;
$green500: #8fc351;
$green400: #a0cc6b;
$green300: #b1d585;
$green200: #c7e1a8;
$green100: #ddedcb;
$green50: #f2f8ea;

// -------- Purple --------
$purple900: #5a4483;
$purple800: #6d5793;
$purple700: #77619c;
$purple600: #826ca6;
$purple500: #8a74ad;
$purple400: #9c89b9;
$purple300: #ad9ec6;
$purple200: #c5bad6;
$purple100: #dcd5e6;
$purple50: #f1eef5;

// -------- Origin --------
$yellow900: #ffa102;
$yellow800: #ffae04;
$yellow700: #ffb505;
$yellow600: #ffbd06;
$yellow500: #ffc307;
$yellow400: #ffcc2c;
$yellow300: #ffd551;
$yellow200: #ffe183;
$yellow100: #ffedb5;
$yellow50: #fff8e1;

// -------- Red --------
$red900: #db3631;
$red800: #e14842;
$red700: #e4524c;
$red600: #e75c56;
$red500: #ea645e;
$red400: #ed7b76;
$red300: #f0938e;
$red200: #f5b2af;
$red100: #f9d1cf;
$red50: #fcecec;

// -------- Gray --------
$gray01: #fafafa;
$gray02: #efefef;

$gray900: #3f3f3f;
$gray800: #4d4c4c;
$gray700: #595757;
$gray600: #666565;
$gray500: #727171;
$gray400: #878686;
$gray300: #9fa0a0;
$gray200: #b5b5b6;
$gray100: #c9caca;
$gray50: #e8e8e8;
$gray30: #f1f1f1;
$gray15: #f9f9f9;

:export {
  // -------- Blue --------
  blue11: $blue11;
  blue900: $blue900;
  blue800: $blue800;
  blue700: $blue700;
  blue600: $blue600;
  blue500: $blue500;
  blue400: $blue400;
  blue300: $blue300;
  blue200: $blue200;
  blue100: $blue100;
  blue50: $blue50;
  // -------- Green --------
  green900: $green900;
  green800: $green800;
  green700: $green700;
  green600: $green600;
  green500: $green500;
  green400: $green400;
  green300: $green300;
  green200: $green200;
  green100: $green100;
  green50: $green50;
  // -------- Purple --------
  purple900: $purple900;
  purple800: $purple800;
  purple700: $purple700;
  purple600: $purple600;
  purple500: $purple500;
  purple400: $purple400;
  purple300: $purple300;
  purple200: $purple200;
  purple100: $purple100;
  purple50: $purple50;
  // -------- Origin --------
  yellow900: $yellow900;
  yellow800: $yellow800;
  yellow700: $yellow700;
  yellow600: $yellow600;
  yellow500: $yellow500;
  yellow400: $yellow400;
  yellow300: $yellow300;
  yellow200: $yellow200;
  yellow100: $yellow100;
  yellow50: $yellow50;
  // -------- Red --------
  red900: $red900;
  red800: $red800;
  red700: $red700;
  red600: $red600;
  red500: $red500;
  red400: $red400;
  red300: $red300;
  red200: $red200;
  red100: $red100;
  red50: $red50;
  // -------- Gray --------
  gray01: $gray01;
  gray02: $gray02;
  gray900: $gray900;
  gray800: $gray800;
  gray700: $gray700;
  gray600: $gray600;
  gray500: $gray500;
  gray400: $gray400;
  gray300: $gray300;
  gray200: $gray200;
  gray100: $gray100;
  gray50: $gray50;
  gray30: $gray30;
  gray15: $gray15;
}
