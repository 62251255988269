@use "~styles/global/_color.scss" as *;

@mixin text-overflow($lineClamp: 2) {
  overflow: hidden;
  overflow-wrap: anywhere;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lineClamp;
}

@mixin largeScrollBar {
  // Custom scrollbar
  div::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    &:hover {
      background-color: $gray200;
    }
  }

  ::-webkit-scrollbar-track {
    width: 4px;
    height: 4px;
    border-radius: 0px;
    background-color: $gray30;
  }

  ::-webkit-scrollbar-thumb {
    width: 4px;
    height: 4px;
    background-color: $gray100;
    border-radius: 0px;
    &:hover {
      background-color: $gray200;
    }
  }
}
